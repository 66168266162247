interface MediaParams {
  count?: number
  pageNumber?: number
}

export default () => {
  const { socialNative } = useApi()
  const { configSocialNative: { authToken, uploadToken } } = useFeatureFlags()
  const version = 'v2.2'
  const wrap_responses = 1

  let lastCustomerId: string | null = null

  /**
   * photorankapi return always status 200 but in metadata put the real status code
   */
  const validateResponse = <T extends { metadata: { code: number, message: string, version: string } }>
    (res: T): T => {
    if (![200, 201].includes(res.metadata.code)) throw new Error(res.metadata.message)
    return res
  }

  const getAuthentication = async () =>
    lastCustomerId || socialNative.$auth({ auth_token: authToken, version, wrap_responses })
      .then(validateResponse)
      .then((res) => {
        if ('_embedded' in res.data) lastCustomerId = res.data._embedded.customer.id
        return lastCustomerId || null
      })

  const getMediaByCategoryId = ({ count = 20, pageNumber = 1, categoryId }:
    MediaParams & { categoryId: string }) =>
    socialNative.$getMediaByCategory({
      auth_token: authToken,
      count,
      page_number: pageNumber,
      tag_key: categoryId,
      version,
      wrap_responses
    })
      .then(validateResponse)

  const getMediaByCustomer = ({ count = 20, customerId, pageNumber = 1 }:
    MediaParams & { customerId: string }) =>
    socialNative.$getMediaByCustomer(customerId, {
      auth_token: authToken,
      count,
      page_number: pageNumber,
      version,
      wrap_responses
    })
      .then(validateResponse)

  const getMediaByStream = ({ count = 20, pageNumber = 1, streamId }: MediaParams & { streamId: string }) =>
    socialNative.$getMediaByStream(streamId, {
      auth_token: authToken,
      count,
      page_number: pageNumber,
      version,
      wrap_responses
    })
      .then(validateResponse)

  const getMediaByTagKey = ({ count = 20, pageNumber = 1, tagKey }: MediaParams & { tagKey: string }) =>
    socialNative.$getMediaByTagKey(
      { auth_token: authToken, count, page_number: pageNumber, tag_key: tagKey, version, wrap_responses }
    )
      .then(validateResponse)

  const getStreamByMedia = ({ mediaId }: { mediaId: string }) =>
    socialNative.$getStreamByMedia(mediaId, { auth_token: authToken, version, wrap_responses })
      .then(validateResponse)

  const sendReport = ({ mediaId, email, reason }: { mediaId: string, email: string, reason: string }) =>
    socialNative.$reportMedia(mediaId, { auth_token: authToken, wrap_responses }, { email, reason })
      .then(validateResponse)

  const uploadMedia = ({ userId, file, url, caption }: {
    userId: string
    file: File
    url?: string
    caption?: string
  }) => {
    const formData = new FormData()
    formData.append('file', file)
    Object.entries({ url, caption }).forEach(([key, value]) => {
      if (value) formData.append(key, value)
    })
    return socialNative.$uploadMedia(userId, {
      auth_token: uploadToken,
      wrap_responses,
    }, formData as any)
      .then(validateResponse)
  }

  const stashMedia = (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    return socialNative.$stashMedia({ auth_token: uploadToken, wrap_responses }, formData as any)
      .then(validateResponse)
  }

  const createUser = ({ name, email }: { name: string, email: string }) =>
    socialNative.$createUser({ version, auth_token: uploadToken }, { screen_name: name, email })
      .then(validateResponse)

  return {
    getAuthentication,
    getMediaByCategoryId,
    getMediaByCustomer,
    getMediaByStream,
    getMediaByTagKey,
    getStreamByMedia,
    sendReport,
    uploadMedia,
    stashMedia,
    createUser
  }
}
